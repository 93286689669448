import {
  Box,
  Container,
  Fade,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ContactForm from 'components/contact-form';
import React from 'react';

import { BUSINESS_ADDRESS } from '../constants';
import contactPageImage from '../assets/fallbackFileFormat/contact-image.png';

export default function ContactPage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const StyledHeader = styled(Typography)(() => ({
    color: theme.palette.secondary.main,
  }));
  const translateDistance = matches ? '-50%' : '0%';

  return (
    <Container maxWidth="lg">
      {/* Need to adjust layout of page to center image with grid properly. */}
      <Box paddingTop="1em" paddingBottom="1em">
        <Grid container>
          <Grid item xs={12}>
            <StyledHeader color="secondary.main" variant="h3" fontWeight="fontWeightBold">
              Contacto
            </StyledHeader>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" gutterBottom marginBottom={3} align="justify">
              Si tiene alguna pregunta o quiere hacer una solicitud de servicio. No dude en ponerse
              en contacto con nosotros a través del correo electrónico. Sólo tiene que llenar el
              formulario de solicitud con la información requerida para enviar un correo
              electrónico.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <StyledHeader variant="h5" gutterBottom fontWeight="fontWeightBold">
              Estamos disponibles:
            </StyledHeader>
            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                Disponible de Lunes a Domingo para responder inquietudes sobre financiamiento y
                acuerdos de inversión
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} marginTop={0}>
          <Grid item xs={12} sm={6} md={3}>
            <StyledHeader variant="h5" gutterBottom fontWeight="fontWeightBold">
              Consultas generales
            </StyledHeader>
            <Typography variant="body1"> fvillamar@cofiad.com.ec</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledHeader variant="h5" gutterBottom fontWeight="fontWeightBold">
              Teléfono
            </StyledHeader>
            <Typography variant="body1">(593)-967-618-791</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} marginBottom={3} marginTop={0}>
          <Grid item xs={12} sm={12} md={12}>
            <StyledHeader variant="h5" gutterBottom fontWeight="fontWeightBold">
              Dirección
            </StyledHeader>
            <Typography variant="body1">{BUSINESS_ADDRESS}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} direction="row">
          <Grid item md={6}>
            <div>
              <StyledHeader variant="h3" gutterBottom fontWeight="fontWeightBold">
                Enviar una consulta
              </StyledHeader>
              <ContactForm />
            </div>
          </Grid>
          <Grid item md={6}>
            <Fade in timeout={1000}>
              <img
                src={contactPageImage}
                width="500"
                height="500"
                alt=""
                style={{
                  width: '100%',
                  height: 'auto',
                  transform: `translateY(${translateDistance})`,
                }}
              />
            </Fade>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
